<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      fill="inherit"
      d="M5,3 L19,3 C20.1045695,3 21,3.8954305 21,5 L21,19 C21,20.1045695 20.1045695,21 19,21 L5,21 C3.8954305,21 3,20.1045695 3,19 L3,5 C3,3.8954305 3.8954305,3 5,3 M7,7 L7,9 L9,9 L9,7 L7,7 M11,7 L11,9 L13,9 L13,7 L11,7 M15,7 L15,9 L17,9 L17,7 L15,7 M7,11 L7,13 L9,13 L9,11 L7,11 M11,11 L11,13 L13,13 L13,11 L11,11 M15,11 L15,13 L17,13 L17,11 L15,11 M7,15 L7,17 L9,17 L9,15 L7,15 M11,15 L11,17 L13,17 L13,15 L11,15 M15,15 L15,17 L17,17 L17,15 L15,15 Z"
    />
  </svg>
</template>
<script>
import { ALL_CATEGORY_FILTER_ICON } from '@/constants'

export default {
  name: ALL_CATEGORY_FILTER_ICON,
}
</script>
